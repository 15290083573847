@import "../../import";
@charset "utf-8";

/**
 * セクション
 */
.c-sec {
  & + .c-sec {
    margin-top: 130px;
  }
  &__contents {
    &.c-sec__contents {
    }
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .c-sec {
    & + .c-sec {
      margin-top: pxToVw(160, $sp-width);
    }
    &__contents {
      &.c-sec__contents {
      }
    }
  }
}


