@import "../../import";
@charset "utf-8";

/* ============================================================
   news
============================================================ */

.page-news-detail {
  .cmn-bottom {
    text-align: center;
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-news-detail {
    .cmn-bottom {
    }
  }
}


