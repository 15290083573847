@import "../../import";
@charset "utf-8";

/* ============================================================
   products インデックスページ
============================================================ */

.page-products-index {
  .c-hdg-style02 {
    margin-bottom: 0;
  }
  .products-header {
    box-sizing: border-box;
    height:75px;
    .c-l-base {
      position: relative;
    }
    &__menu {
      position: absolute;
      top: 0;
      right: 15px;
      width: 224px;
      font-size: 1.0rem;
      z-index: 5;
      .select {
        position: relative;
        background: #fff;
        padding: 7px 37px 7px 34px;
        cursor: pointer;
        &:before {
          content: '▼';
          position: absolute;
          top: 50%;
          right: 14px;
          transform: translateY(-50%);
        }
        span {
          overflow: hidden;
          display: block;
          border-right: 1px solid $color-gray;
          line-height: 18px;
          word-wrap: break-word;
        }
      }
      .dropdown {
        padding-top: 2px;
        width: 100%;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity .4s $ease-out04;
        &.is-show {
          height: 100%;
          opacity: 1;
        }
        li {
          a {
            display: block;
            padding: 8px 37px;
            text-decoration: none;
            transition: all .4s $ease-out04;
          }
        }
        &__cat {
          background: #F2F2F2;
          a {
            &:hover,
            .is-active {
              background: #fff;
            }
          }
        }
        &__brand {
          margin-top: 2px;
          background: #E5E5E5;
          a {
            &:hover,
            .is-active {
              background: #F4F4F4;
            }
          }
        }

      }
    }
  }

  .products-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: -33px 0;
    &__item {
      box-sizing: border-box;
      padding: 33px pxToVw(36, $bp-desktop-up);
      width: 25%;
      text-align: center;
      img {
        width: 100%;
        height: auto;
        transition: all .4s $ease-out04;
      }
      .txt {
        margin-top: 34px;
        font-size: 1.0rem;
      }
      a {
        text-decoration: none;
        &:hover {
          img {
            opacity: .7;
          }
        }
      }

    }

    &__pager {
      margin-top: 117px;
    }
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-products-index {
    .products-header {
      height: auto;
      .c-l-base{
        &.is-show {
          z-index: 15000;
        }
      }

      &__menu {
        position: static;
        width: pxToVw(400, $sp-width);
        margin: 0 auto;
        .select {
          padding: pxToVw(8, $sp-width) pxToVw(67, $sp-width) pxToVw(8, $sp-width) pxToVw(58, $sp-width);
          font-size: pxToVw(20, $sp-width);
          &:before {
            right: pxToVw(25, $sp-width);
          }
          span {
            line-height: pxToVw(32, $sp-width);
          }
        }
        .dropdown {
          position: fixed;
          top:0;
          left: 0;
          bottom: auto;
          width: 100%;
          //height: 100%;
          transform: translateY(0);
          background: #F2F2F2;
          font-size: pxToVw(22, $sp-width);

          &__close {
            position: fixed;
            top: pxToVw(133, $sp-width);
            right: pxToVw(100, $sp-width);
            width:pxToVw(50, $sp-width);
            height:pxToVw(50, $sp-width);
            cursor: pointer;
            [class^="line"] {
              position: absolute;
              top: 50%;
              left: 50%;
              background: #707070;
              transform-origin: 0% 0%;
              transition: all .2s .3s $ease-out01;
              width: pxToVw(50, $sp-width);
              height: pxToVw(2, $sp-width);
            }
            .line01 {
              transform: rotate(45deg) translate(-50%, -50%);
            }
            .line02 {
              transform: rotate(-45deg) translate(-50%, -50%);
            }
          }

          .wrap {
            overflow-y: auto;
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
          ul {
            width: 100%;
          }
          li {
            a {
              padding: pxToVw(15, $sp-width) pxToVw(145, $sp-width);
            }
          }
          &__cat {
            padding-top: pxToVw(240, $sp-width);
            background: none;
          }
          &__brand {
            padding-bottom: pxToVw(222, $sp-width);
            margin-top: pxToVw(55, $sp-width);
            background: none;
            a {
              &:hover,
              .is-active {
                background: #fff;
              }
            }
          }
        }
      }
    }

    .c-sec__contents {
      .c-l-base {
        padding: 0;
      }
    }
    .products-list {
      margin:pxToVw(-38, $bp-desktop-up) 0;
      &__item {
        box-sizing: border-box;
        padding: pxToVw(38, $bp-desktop-up) pxToVw(28, $bp-desktop-up);
        width: 33%;
        .txt {
          margin-top: pxToVw(10, $bp-desktop-up);
          font-size: pxToVw(16, $bp-desktop-up);
        }
      }

      &__pager {
        margin-top: pxToVw(132, $bp-desktop-up);

      }
    }
  }
}
