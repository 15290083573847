@charset "UTF-8";

/// ============================================================
/// FUNCTION
/// プロジェクト用
/// ============================================================

@function pxToVw($pxValue, $breakpoint) {
  @return (($pxValue / $breakpoint) * 100) + vw;
}

@function pxToVh($pxValue, $breakpoint) {
  @return (($pxValue / $breakpoint) * 100) + vh;
}


