@import "../../import";
@charset "utf-8";

/* ============================================================
   products
============================================================ */

.page-products {
  .products-header {
    margin-bottom: 100px;
    background: $base-color;
    padding: 22px 0;
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-products {
    .products-header {
      margin-bottom: pxToVw(94, $sp-width);
      padding: pxToVw(38, $sp-width) 0;
    }
  }
}
