@import "../../import";
@charset "utf-8";

/**
 * 配置
 */
.u-ta-c {
	text-align: center;
}
.u-ta-l {
	text-align: left;
}
.u-ta-r {
	text-align: right;
}

