@import "../../import";
@charset "utf-8";


/**
 * ページャー
 */
.c-pager {
  line-height: 1;
  ul {
    text-align: center;
    li {
      display: inline-block;
      margin: 0 6px;
    }
  }
  a {
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
    &.is-active {
      font-weight: bold;
    }
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .c-pager {
    ul {
      li {
        display: inline-block;
        margin: 0 pxToVw(25, $bp-desktop-up);
      }
    }
  }
}
