@import "../../import";
@charset "utf-8";

 /**
  * worksリスト
  */
.p-col-list {
  //overflow: hidden;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  .block {
    box-sizing: border-box;
    letter-spacing: .2rem;

    a {
      display: block;
      text-decoration: none;
    }

    .img {
      margin-bottom: 12px;
      transition: all .4s $ease-out04;
    }
    .txt-wrap {
      position: relative;
      display: block;
      > span {
        display: block;
      }
    }

    &._type01 {
      .copy {
        font-size: 1.2rem;
      }
      .name {
        font-size: 1.7rem;
      }
    }

    &._type02 {
      .name {
        letter-spacing: 0.2rem;
      }
      .em {
        display: block;
        font-size: 1.7rem;
        line-height: 1.4;
      }
      .store {
        display: block;
        font-size: 1.0rem;
      }
      .client {
        margin-top: 7px;
        font-size: 1.0rem;
      }
    }

    a.is-hover {
      .img {
        transition: all 1s $ease-out04;
        opacity: .9;
        filter: saturate(1.4);
        //filter: brightness(1.1);
        transform: scale(.98) rotate(0.001deg);
      }
    }
  }
}


/* --------------------------------------------------
   mediaquery -> for-tablet-portrait-up
-------------------------------------------------- */

@include for-tablet-portrait-up {
  .p-col-list {
    &._pc-col01 {
      ul {
        margin: -125px 0 0;
      }
      [class^="block"] {
        width: 100%;
        padding: 125px 0 0;
      }
    }
    &._pc-col02 {
      ul {
        margin: -70px -5px 0;
      }
      [class^="block"] {
        width: 50%;
        padding: 70px 5px 0;
      }
    }
    &._pc-col03 {
      ul {
        margin: -70px -6px 0;
      }
      [class^="block"] {
        width: 33.3%;
        padding: 70px 6px 0;
      }
    }
    &._pc-col04 {
      ul {
        margin: -78px -5px 0;
      }
      [class^="block"] {
        width: 25%;
        padding: 78px 5px 0;
      }
    }
  }
}



/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .p-col-list {
    &._sp-col01 {
      ul {
        margin: - pxToVw(110, $sp-width) 0 0;
      }
      [class^="block"] {
        padding: pxToVw(110, $sp-width) 0 0;
        width: 100%;

        .txt-wrap {
          padding-right:pxToVw(24, $sp-width);
          padding-left:pxToVw(24, $sp-width);
        }
      }
    }
    &._sp-col02 {
      ul {
        margin: (- pxToVw(80, $sp-width)) (- pxToVw(10, $sp-width)) 0;
      }
      [class^="block"] {
        width: 50%;
        padding: pxToVw(80, $sp-width) pxToVw(10, $sp-width) 0;
      }
    }
    &._sp-col03 {
      ul {
      }
      [class^="block"] {
        width: 33.3%;
      }
    }

    .block {
      .img {
        margin-bottom: pxToVw(32, $sp-width);
      }

      &._type01 {
        .txt-wrap {
          line-height: 1.5;
        }
        .copy {
          font-size: pxToVw(18, $sp-width);
        }
        .name {
          font-size: pxToVw(26, $sp-width);
        }
      }

      &._type02 {
        .name {
          letter-spacing: 0.2rem;
          line-height: 1.5;
        }
        .em {
          font-size: pxToVw(22, $sp-width);
        }
        .store {
          display: block;
          font-size: pxToVw(18, $sp-width);
          span {
          }
        }
        .client {
          //display: none;
          margin-top: pxToVw(20, $sp-width);
          font-size: pxToVw(18, $sp-width);
          line-height: 1.3;
        }
      }

      a.is-hover {
        .img {
          transform: scale(.94);
        }
      }
    }
  }
}


