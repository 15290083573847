@import "../../import";
@charset "utf-8";

/* ============================================================
   company
============================================================ */

.page-company {
  .sec {
    .c-l-base {
      max-width: 750px;
      margin: 0 auto;
    }

    &--wildwood,
    &--repair {
      .sec__cont {
        margin-top: -1.5em;
        p, ul {
          margin-top: 1.5em;
        }
        ul {
          margin-left: 1em;
        }
      }
    }
    &--info {
      table {
        width: 450px;
        margin: 0 auto;
        th, td {
          padding: 15px 0;
        }
        th {
          width: 122px;
          font-size: 1.2rem;
          font-weight: normal;
          text-align: left;
        }
        td {
        }
      }
    }
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-company {
    .sec {
      .c-l-base {
        max-width: 100%;
      }
      &--wildwood,
      &--repair {
        .sec__cont {
          margin-top: -1.5em;
          font-size: pxToVw(24, $sp-width);
          p, ul {
            margin-top: 1.5em;
          }
          ul {
            margin-left: 1em;
          }
        }
      }
      &--info {
        table {
          width: 100%;
          th, td {
            padding: pxToVw(18, $sp-width) 0;
          }
          th {
            text-align: right;
            width: 8em;
            font-size: pxToVw(20, $sp-width);
          }
          td {
            padding-left: 1em;
            font-size: pxToVw(24, $sp-width);
          }
        }
      }
    }
  }
}


