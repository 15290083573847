@import "../../import";
@charset "utf-8";

/* ============================================================
   products 詳細ページ
============================================================ */




.page-products-detail {
  .products-header {
    .btn {
      float: right;
      a {
        box-sizing: border-box;
        display: inline-block;
        line-height: 30px;
        padding: 0 28px;
        text-decoration: none;
        letter-spacing: .1em;
        transition: all .4s $ease-out04;
        font-family:$mincho-font;
        background: #fff;
        font-size: 1.0rem;
        span {
          position: relative;
          padding-left: 1em;

          &:before {
            content: '>';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
        &:hover {
          background: $color-gray;
        }
      }
    }
  }

  .item-info {
    position: relative;

    .bread-crumb {
      font-size: 1.0rem;
      line-height: 1.2;
      li {
        position: relative;
        display: inline-block;
        + li {
          padding-left: 27px;
          &:before {
            content: '>';
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
          }
        }
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__img {
      // width: calc(100% - 370px);
      width: calc(100% - 420px);
      li + li {
        margin-top: pxToVw(90, $bp-desktop-up);
      }
    }
    &__detail {
      position: absolute;
      top: 0;
      right: 0;
      box-sizing: border-box;
      width: 370px;



      //padding-left: pxToVw(60, $bp-desktop-up);
      .wrap {
      }
      .bread-crumb {
        margin-bottom: 60px;
      }

      .item-name {
        border-bottom: 1px solid $color-gray;
        padding-bottom: 10px;
        line-height: 1.4;
        .cat {
          font-size: 1.0rem;
          margin-bottom: 6px;
        }
        h1 {
          font-size: 2.2rem;
          font-family: $mincho-font;
        }
      }
      .item-detail {
        padding: 48px 0;
        border-bottom: 1px solid $color-gray;
        font-size: 1.2rem;
        table {
          width: 100%;
        }
        tr {
          th, td {
            box-sizing: border-box;
            padding-top: 4px;
            padding-bottom: 4px;
            font-weight: normal;
          }
          th {
            text-align: right;
            width: 35%;
            span {
              position: relative;
              display: block;
              padding-right: 10px;
              &:after {
                position: absolute;
                top: 0;
                right: 0;
                content: ':';
              }
            }
          }
          td {
            text-align: left;
            width:65%;
            padding-left: 10px;
          }
        }
      }
      .contact-link {
        margin-top: 41px;
        text-align: center;
      }
    }
  }
}


/* --------------------------------------------------
   mediaquery -> for-desktop-under
-------------------------------------------------- */

@include for-desktop-under {
  .page-products-detail {
    .item-info {
      // &__img {
      //   width: pxToVw(780, $bp-desktop-up);
      // }
      // &__detail {
      //   width: pxToVw(370, $bp-desktop-up);
      // }
    }
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-products-detail {
    padding-bottom: pxToVw(65, $sp-width);
    .products-header {
      .btn {
        a {
          line-height: pxToVw(32, $sp-width);
          padding: pxToVw(17, $sp-width) pxToVw(40, $sp-width) pxToVw(17, $sp-width) pxToVw(40, $sp-width);
          font-size: pxToVw(22, $sp-width);
        }
      }
    }

    .item-info {
      display: block;
      .bread-crumb {
        font-size: pxToVw(16, $sp-width);
        li {
          + li {
            padding-left: pxToVw(44, $sp-width);
            &:before {
              left: pxToVw(18, $sp-width);
            }
          }
        }
      }
      &__img {
        margin-top:pxToVw(134, $sp-width);
        width: 100%;
        li + li {
          margin-top: pxToVw(30, $sp-width);
        }
      }
      &__detail {
        position: static;
        width: 100%;
        padding-left: 0;
        .bread-crumb {
          margin-bottom: pxToVw(58, $sp-width);
        }

        .item-name {
          padding-bottom: pxToVw(55, $sp-width);
          .cat {
            font-size: pxToVw(16, $sp-width);
            margin-bottom: pxToVw(8, $sp-width);
          }
          h1 {
            font-size: pxToVw(30, $sp-width);
          }
        }
        .item-detail {
          padding: pxToVw(73, $sp-width) 0;
          tr {
            th {
            }
            td {
              padding-left: pxToVw(47, $sp-width);
            }
          }
        }
        .contact-link {
          margin-top: pxToVw(66, $sp-width);
        }
      }

      &__bottom {
        margin-top: pxToVw(65, $sp-width);
      }
    }
  }
}
