@import "../../import";
@charset "utf-8";

/* ============================================================
   contact 入力画面
============================================================ */

.page-contact-index {
  .c-sec__contents {
    > .c-l-base {
      position: relative;
    }
    .block {
      + .block{
        margin-top: 36px;
      }
      .hdg {
        position: relative;
        float: left;
        width: 236px;
        padding-right: 94px;
        text-align: right;
        letter-spacing: .1em;
        line-height: 1;
        span {
          display: block;
        }
        label {
          font-size: 1.4rem;
          font-family:$mincho-font;
          line-height:1;
        }
        .sub {
          margin-top: 3px;
          font-size: 1.0rem;
        }
        .required {
          position: absolute;
          left: 240px;
          bottom: .5em;
          color: $txt-color-em;
          font-size:1.0rem;
          font-weight: bold;
        }
      }
      .cont {
        overflow: hidden;
      }
      .error {
        color: $txt-color-em;
        font-size: 1.0rem;
        margin-bottom: 9px;
      }
    }
    .message {
      .c-form-textarea {
        height: 272px;
      }
    }
    .consent {
      margin-top: 70px;
    }
    .submit {
      margin-top: 94px;
      .c-btn-style01 {
        margin-top: 0;
      }
      .error {
        display: block;
        margin-top: 18px;
        span {
          display: inline-block;
          min-width: 329px;
          text-align: center;
        }
      }
      button:disabled {
        cursor: default;
      }
    }

    .formError {
      color: $txt-color-em;
      font-size: 1.0rem;

      position: static !important;
      margin-top: 0 !important;
      margin-bottom: 5px !important;


      br {
        display: none;
      }
      + input, + textarea {
        border-color: $txt-color-em;
      }
    }
  }

  @keyframes spinnerAnime {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .wpcf7-form {
    .ajax-loader {
      visibility: hidden;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 26px;
      height: 26px;
      transform: translate(180%, -50%);
      transition: all .8s $ease-out06;
      pointer-events: none;
      background: none;

      &:after, &:before {
        box-sizing: border-box;
        display: inline-block;
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
      &:after {
        border: solid 4px $color-gray;
      }
      &:before {
        z-index: 5;
        border-top: solid 4px $color-gray-dark;
        border-right: solid 4px transparent;
        border-bottom: solid 4px transparent;
        border-left: solid 4px transparent;
        animation: spinnerAnime .6s linear infinite;
      }
    }
  }
  div.wpcf7-response-output{
    border: 0;
    padding-left: 236 + 94px;
    font-size: 1.0rem;
  }
  div.wpcf7-mail-sent-ok {
  }
  div.wpcf7-validation-errors {
    color: $txt-color-em;
  }
  .wpcf7-not-valid-tip {
    display: none;
  }
}


/* --------------------------------------------------
   mediaquery -> for-desktop-under
-------------------------------------------------- */

@include for-desktop-under {
  .page-contact-index {
    .c-sec__contents {
      .block {
        .submit {
          .c-btn-style01 {
            min-width: pxToVw(329, $bp-desktop-up);
          }
          .error {
            span {
              min-width: pxToVw(329, $bp-desktop-up);
            }
          }
        }
      }
    }
  }
}




/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-contact-index {
    .c-sec__contents {
      .c-form-text,
      .c-form-textarea {
        font-size: pxToVw(24, $sp-width);
      }
      .c-form-radio {
        font-size: pxToVw(24, $sp-width);
      }
      .c-form-checkbox {
        font-size: pxToVw(24, $sp-width);
      }

      .block {
        + .block{
          margin-top: pxToVw(60, $sp-width);
        }
        .hdg {
          float: none;
          width: 100%;
          padding-right: 0;
          text-align: left;
          line-height: 1.6;
          label {
            font-size: pxToVw(30, $sp-width);
          }
          .sub {
            font-size: pxToVw(16, $sp-width);
            line-height: 1.2;
          }
          .required {
            left: auto;
            right: 0;
            bottom: 0;
            font-size: pxToVw(16, $sp-width);
          }
        }
          .cont {
          .inner {
            margin-top: 0;
          }
        }
        input, textarea {
          margin-top: pxToVw(15, $sp-width);
        }
        .formError {
          margin-top: pxToVw(5, $sp-width) !important;
        }
        .error {
          font-size: pxToVw(16, $sp-width);
          margin-bottom: pxToVw(4, $sp-width);
          line-height: 1.4;
          margin-top: pxToVw(10, $sp-width);
        }
        .submit {
          .c-btn-style01 {
            min-width: pxToVw(277, $sp-width);
          }
        }
      }
      .message {
        .c-form-textarea {
          height: pxToVw(345, $sp-width);
        }
      }
      .consent {
        margin-top: pxToVw(87, $sp-width);
      }
      .submit {
        text-align: center;
        margin-top: pxToVw(128, $sp-width);
        .btn {
        }
        .error {
          margin-top: pxToVw(18, $sp-width);
          span {
            min-width: 0;
          }
        }
      }
    }

    .wpcf7-form {
      .ajax-loader {
        width: pxToVw(40, $sp-width);
        height: pxToVw(40, $sp-width);
        &:after, &:before {
          border-width: pxToVw(6, $sp-width);
        }
      }
    }

    div.wpcf7-response-output{
      font-size: pxToVw(16, $sp-width);
      padding-left: 0;
      text-align: center;
    }
    div.wpcf7-mail-sent-ok {
    }
    div.wpcf7-validation-errors {
    }
  }
}
