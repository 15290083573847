@charset "UTF-8";

/// ============================================================
/// 変数
/// プロジェクト用
/// ============================================================

// カラー
$txt-color-main: #3C3C3C; // 文字色 背景色などで黒色を使う時は$base-colorを使ってください
$txt-color-em: #FF0000; // 強調色 フォームのバリデーションエラーなどに使います
$brand-color: #FD970F; // ブランドカラー
$base-color: #212121;
$color-gray: #D8D8D8;
$color-gray-dark: #6E6E6E;
$placeholder-color: #ACACAC;

// ブレイクポイント
$bp-desktop-up: 1280;
$bp-tb-port-up: 768;


// スマホデザイン横幅
$sp-width: 750;
$sp-height: 1336;


$mincho-font: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$gothic-font: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;





