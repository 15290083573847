@import "../../import";
@charset "utf-8";

/**
 * レイアウト枠
 */
.c-l-base {
	box-sizing: border-box;
	max-width: $bp-desktop-up + px;
	margin: 0 auto;
	padding: 0 30px;
}
.c-l-cont {
	padding: 0 50px;
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
	.c-l-base {
		max-width: none;
		padding-right:pxToVw(30, $sp-width);
		padding-left:pxToVw(30, $sp-width);
	}
	.c-l-cont {
		padding-right:pxToVw(24, $sp-width);
		padding-left:pxToVw(24, $sp-width);
	}
}

