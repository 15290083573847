@import "../import";
@charset "utf-8";

/* ============================================================
   notfound
============================================================ */

.page-notfound {
  .c-sec {
    padding-top: 120px;
    text-align: center;
  }

  .btn-back {
    margin-top: 70px;
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-notfound {
    .c-sec {
      padding-top: pxToVw(119, $sp-width);
    }

    .btn-back {
      margin-top: pxToVw(70, $sp-width);
    }
  }
}


