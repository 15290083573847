@import "../import";
@charset "utf-8";

/* ============================================================
   Footer
============================================================ */
.l-footer {
  position: relative;
  padding: 90px 0;
  //background: $base-color;
  background: #3C3C3C;
  color: #A2A2A2;
  z-index: 10000;

  // drive info
  .info-wrap {
    overflow: hidden;
    display: flex;
    align-self: center;
    justify-content: space-between;
    padding: 0 200px;
    align-items: flex-end;
  }

  .corp-info {
    &__logo {
      line-height: 1;
      margin-bottom: 30px;
      svg {
        width: 150px;
        height: 40px;
        fill: #A2A2A2;
        transition: all .4s $ease-out04;
      }
      a:hover {
        svg {
          fill: #ffffff;
        }
      }
    }
    &__address {
      a {
        color: #A2A2A2;
        text-decoration: none;
        transition: all .4s $ease-out04;
        &:hover {
          color: #fff;
        }
      }
      dt {
        margin-bottom: 22px;
        font-family: $mincho-font;
      }
      dd {
        font-size: 1.2rem;
        + dd {
          margin-top: 3px;
        }
      }
    }
  }

  .ft-bottom {
    &__sns-links {
      text-align: right;
      line-height: 1;
      li {
        display: inline-block;
      }
      a {
        display: inline-block;
        width:30px;
        height: 30px;
        text-align: center;
      }
      svg {
        vertical-align: middle;
        fill: #A2A2A2;
        transition: all .4s $ease-out04;
      }
      .ig {
        svg {
          width: 18px;
          height: 18px;
        }
      }
      a:hover {
        svg {
          fill: #ffffff;
        }
      }
    }
    &__copyright {
      margin-top: 20px;
      font-size: 1.0rem;
    }
  }
}



/* --------------------------------------------------
   mediaquery -> for-desktop-under
-------------------------------------------------- */

@include for-desktop-under {
  .l-footer {
    padding: pxToVw(90, $bp-desktop-up) 0;

    // drive info
    .info-wrap {
      padding: 0 pxToVw(200, $bp-desktop-up);
    }
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .l-footer {
    border: 0;
    padding: 0;

    .ft-content {
      padding: pxToVw(90, $sp-width) 0 pxToVw(17, $sp-width);
    }

    // drive info
    .info-wrap {
      overflow: hidden;
      display: inherit;
      padding: 0 pxToVw(24, $sp-width);
    }

    .corp-info {
      &__logo {
        margin-bottom: pxToVw(55, $sp-width);
        svg {
          width: pxToVw(206, $sp-width);
          height: pxToVw(55, $sp-width);
        }
      }
      &__address {
        dt {
          margin-bottom: pxToVw(45, $sp-width);
          font-size: pxToVw(22, $sp-width);
        }
        dd {
          font-size: pxToVw(16, $sp-width);
          + dd {
            margin-top: pxToVw(3, $sp-width);
          }
        }
      }
    }

    .ft-bottom {
      margin-top: pxToVw(38, $sp-width);
      &__sns-links {
        text-align: left;
        a {
          width: pxToVw(30, $sp-width);
          height: pxToVw(30, $sp-width);
        }
        .ig {
          svg {
            width: pxToVw(30, $sp-width);
            height: pxToVw(30, $sp-width);
          }
        }
      }
      &__copyright {
        margin-top: pxToVw(60, $sp-width);
        font-size: pxToVw(14, $sp-width);
      }
    }
  }
}
