@import "../../import";
@charset "utf-8";

/**
 * フォーム
 */

input::-webkit-input-placeholder{
  color: $placeholder-color;
}
/* Firefox */
input::-moz-placeholder{
  color: $placeholder-color;
}
/* Firefox 18以前 */
input:-moz-placeholder{
  color: $placeholder-color;
}
/* IE */
input:-ms-input-placeholder{
  color: $placeholder-color;
}
/* Chrome, Safari */
::-webkit-input-placeholder{
  color: $placeholder-color;
}
/* Firefox */
::-moz-placeholder{
  color: $placeholder-color;
}
/* Firefox 18以前 */
:-moz-placeholder{
  color: $placeholder-color;
}
/* IE */
:-ms-input-placeholder{
  color: $placeholder-color;
}

.c-form-text,
.c-form-textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: vertical;
  outline: 0;
  border: 0;
}

/**
 * フォーム: テキスト
 */
.c-form-text {
  border: 1px solid #CCCCCC;
  background: #F4F4F4;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 29px;
  letter-spacing: .1em;
}

/**
 * フォーム: テキストエリア
 */
.c-form-textarea {
  border: 1px solid #CCCCCC;
  background: #F4F4F4;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 29px;
  letter-spacing: .1em;
}


/**
 * フォーム: セレクトボックス
 */
.c-form-select {
}

/**
 * フォーム: ラジオボタン
 */
.c-form-radio {
  display: inline-block;
  line-height: 1.6;

  input[type="radio"] {
    display: none;
  }

  label {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    &:after {
      box-sizing: border-box;
      position: absolute;
      top: 0.15em;
      left: 0;
      display: block;
      width: 18px;
      height: 18px;
      border: 1px solid #CCCCCC;
      border-radius: 50%;
      content: '';
      background: #F4F4F4;
    }

    &:before {
      box-sizing: border-box;
      transition: opacity 0.1s linear;
      position: absolute;
      top: calc(0.15em + 4px);
      left: 4px;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #212121;
      content: '';
      opacity: 0;
      z-index: 5;
    }
  }

  input[type=radio]:checked + label:before {
    opacity: 1;
  }
}


/**
 * フォーム: チェックボックス
 */
.c-form-checkbox {
  display: inline-block;
  line-height: 1.6;

  label {
    cursor: pointer;
    input[type="checkbox"] {
      visibility: hidden;
      height: 0px;
      text-indent: -10000px;
      position: fixed;
      top: 0;
      left: 0;
    }

    span {
      display: block;
      position: relative;
      padding-left: 30px;
      &:after {
        box-sizing: border-box;
        position: absolute;
        top: 0.15em;
        left: 0;
        display: block;
        width: 18px;
        height: 18px;
        border: 1px solid #CCCCCC;
        border-radius: 50%;
        content: '';
        background: #F4F4F4;
      }

      &:before {
        box-sizing: border-box;
        transition: opacity 0.1s linear;
        position: absolute;
        top: calc(0.15em + 4px);
        left: 4px;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #212121;
        content: '';
        opacity: 0;
        z-index: 5;
      }
    }
  }

  input[type=checkbox]:checked + span {
    &:before {
      opacity: 1;
    }
  }
}

/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  /**
   * フォーム: テキスト
   */
  .c-form-text {
    border-radius: pxToVw(5, $sp-width);
    padding: pxToVw(23, $sp-width) pxToVw(25, $sp-width);
  }

  /**
   * フォーム: テキストエリア
   */
  .c-form-textarea {
    border-radius: pxToVw(5, $sp-width);
    padding: pxToVw(23, $sp-width) pxToVw(25, $sp-width);
  }


  /**
   * フォーム: セレクトボックス
   */
  .c-form-select {
  }

  /**
   * フォーム: ラジオボタン
   */
  .c-form-radio {
    display: inline-block;
    line-height: 1.6;

    input[type="radio"] {
      display: none;
    }

    label {
      display: block;
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      &:after {
        box-sizing: border-box;
        position: absolute;
        top: 0.15em;
        left: 0;
        display: block;
        width: 18px;
        height: 18px;
        border: 1px solid #CCCCCC;
        border-radius: 50%;
        content: '';
        background: #F4F4F4;
      }

      &:before {
        box-sizing: border-box;
        transition: opacity 0.1s linear;
        position: absolute;
        top: calc(0.15em + 4px);
        left: 4px;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #212121;
        content: '';
        opacity: 0;
        z-index: 5;
      }
    }

    input[type=radio]:checked + label:before {
      opacity: 1;
    }
  }


  /**
   * フォーム: チェックボックス
   */
  .c-form-checkbox {
    display: inline-block;
    line-height: 1.6;


    label {
      span {
        display: block;
        position: relative;
        padding-left: 30px;
        &:after {
          top: 0.15em;
          left: 0;
          width: 18px;
          height: 18px;
          border: 1px solid #CCCCCC;
          background: #F4F4F4;
        }

        &:before {
          top: calc(0.15em + 4px);
          left: 4px;
          width: 10px;
          height: 10px;
          background-color: #212121;
        }
      }
    }

  }
}
