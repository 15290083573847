@import "../../import";
@charset "utf-8";

/**
 * 強調
 */
.u-em-w{
	font-weight: bold;
}
// .u-em-c{
// 	color: ;
// }

