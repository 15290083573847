@import "../import";
@charset "utf-8";

/* ============================================================
   Header
============================================================ */
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 12000;
  color: #000;
  a {
    color: #000;
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    background: #fff;
    width: 100%;
    height: 100%;
  }
  .content {
    position: relative;
    display: flex;
    height: 102px;
    padding: 0 36px;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }
  .logo {
    a {
      display: block;
      transition: all .4s $ease-out04;
      &:hover {
        opacity: .5;
      }
    }
    svg {
      width: 129px;
      height: 33px;
      vertical-align: middle;
      .cls-1 {
        fill: #414141;
      }
    }
  }
  .gnav {
    display: flex;
  }
  .nav-links {
    display: flex;
    justify-content: center;
    margin-right: -36px;
    li {
      display: inline-block;
    }
    a {
      position: relative;
      display: inline-block;
      padding: 8px 36px 8px 36px;
      line-height:1;
      text-align: center;
      text-decoration: none;
      transition: opacity .3s $ease-out03;

      span {
        position: relative;
        display: block;
      }
      .name {
        font-size:1.4rem;
        font-family: $mincho-font;
        letter-spacing: .1em;
      }
      .sub {
        margin-top:4px;
        font-size:1.0rem;
      }
      &:hover,
      &.is-active {
        opacity: 0.65;
      }
    }
    .btn-contact {
      border-left: 1px solid #CCCCCC;
      a {
        //padding-right: 0;
      }
    }
  }
}




/* --------------------------------------------------
   mediaquery -> for-desktop-under
-------------------------------------------------- */

@include for-desktop-under {
  .l-header {
    .logo {
      svg {
        width: pxToVw(129, $bp-desktop-up);
        height: pxToVw(33, $bp-desktop-up);
      }
    }
    .gnav {
      font-size: pxToVw(14, $bp-desktop-up);
    }

    .content {
      padding: 0 pxToVw(36, $bp-desktop-up);
    }

    .nav-links {
      margin-right: pxToVw(-36, $bp-desktop-up);

      a {
        padding: 8px pxToVw(36, $bp-desktop-up) 8px pxToVw(36, $bp-desktop-up);
        .name {
          font-size: pxToVw(14, $bp-desktop-up);
        }
        .sub {
          font-size: pxToVw(10, $bp-desktop-up);
        }
      }
    }
  }
}



/* --------------------------------------------------
   mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .l-header {
    overflow: hidden;
    height: pxToVw(116, $sp-width);
    top: 0;
    opacity: 1;

    //position: fixed;
    //top: 0;
    //left: 0;
    //width: 100%;
    //background: #FFF;
    //z-index: 10000;

    .content {
      min-height: 100vh;
      height: auto;
    }
    .logo {
      position: absolute;
      top: pxToVw(36, $sp-width);
      left: pxToVw(49, $sp-width);
      svg {
        width: pxToVw(183, $sp-width);
        height: pxToVw(44, $sp-width);
      }
    }
    .nav-wrap {
      position: absolute;
      top: pxToVw(116, $sp-width);
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding: pxToVw(116, $sp-width) 0;
      height: calc(100vh - #{pxToVw(116, $sp-width)});
      overflow-y: auto;
      margin-right: 0;
    }
    .gnav {
      display: flex;
      font-size: pxToVw(30, $sp-width);
      text-align: center;
      align-items: center;
      min-height: 100%;
      flex-direction: column;
      justify-content: center;
    }

    .nav-links {
      display: block;
      li {
        display: block;
        margin: pxToVw(30, $sp-width) 0;
      }
      a {
        display: inline-block;
        padding: pxToVw(10, $sp-width) 0;

        &:before {
          width: 100%;
        }

        span {
          padding: 0;
          letter-spacing: .2em;
          &:before {
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .name {
          font-size: pxToVw(30, $sp-width);
        }
        .sub {
          margin-top: pxToVw(10, $sp-width);
          font-size: pxToVw(16, $sp-width);
        }

        &.is-active,
        &:hover {
        }
      }
      .btn-contact {
        border-left: 0;
      }
    }

    .sns-links {
      padding-bottom: pxToVw(33, $sp-width);
      margin: pxToVw(20, $sp-width) 0 0;
      padding-left: pxToVw(20, $sp-width);
      justify-content: center;
      line-height: 1;
      li {
        margin: 0 pxToVw(13, $sp-width);
      }
      a {
        width: pxToVw(50, $sp-width);
        height: pxToVw(50, $sp-width);
        transition: all .4s $ease-out04;

        &:hover {
          opacity: 0.5;
        }
      }
      .ig {
        svg {
          width: pxToVw(32, $sp-width);
          height: pxToVw(32, $sp-width);
        }
      }
    }

    .nav-toggle {
      visibility: hidden;
      position: absolute;
      top: pxToVw(0, $sp-width);
      right: pxToVw(0, $sp-width);
      width: 116px;
      height: 116px;
      //width: pxToVw(116, $sp-width);
      //height: pxToVw(116, $sp-width);
      z-index: 1000;
      cursor: pointer;
      transform-origin: 100% 0;

      &.is-loaded {
        visibility: visible;
      }

      .wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 44px;
        //width: pxToVw(60, $sp-width);
        //height: pxToVw(42, $sp-width);
      }

      .menu {
        [class^="line"] {
          position: absolute;
          width: 60px;
          height: 4px;
          //width: pxToVw(60, $sp-width);
          //height: pxToVw(2, $sp-width);
          background: $txt-color-main;
        }
        .line01 {
          top: 0;
          transition: opacity .4s .2s $ease-out01, transform .4s .2s $ease-out01, background .2s $ease-out01;
        }
        .line02 {
          top: 20px;
          //top: pxToVw(20, $sp-width);
          transition: opacity .4s .25s $ease-out01, transform .4s .25s $ease-out01, background .2s $ease-out01;
        }
        .line03 {
          top: 40px;
          //top: pxToVw(40, $sp-width);
          transition: opacity .4s .3s $ease-out01, transform .4s .3s $ease-out01, background .2s $ease-out01;
        }
      }

      .close {
        [class^="line"] {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0;
          height: 4px;
          background: $txt-color-main;
          transform-origin: 0% 0%;
          opacity: 0;
          transition: all .2s .0s $ease-out01;
        }
        .line01 {
          transform: rotate(45deg) translate(-50%, -50%);
        }
        .line02 {
          transform: rotate(-45deg) translate(-50%, -50%);
        }
      }
    }



    /* メニューの開閉・表示/非表示
    ------------------------------------------------- */
    // 初期設定
    //transition: none;
    transition: height .8s cubic-bezier(0.19, 1, 0.22, 1);

    &:before {
      //opacity: 0;
      transition: opacity .6s cubic-bezier(0.19, 1, 0.22, 1);
    }

    .content {
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;
      padding: pxToVw(116, $sp-width) 0;
      //height: 100vh;
      //overflow-y: scroll;
      //display: none;
    }

    // スマホで固定ヘッダーのロゴを非表示
    &.is-hide-splogo {
      &:before {
        opacity: 0;
      }
      .logo {
        visibility: hidden;
        opacity: 0;
        transition: opacity .6s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    // ナビ展開クラス
    &.is-open {
      opacity: 1;
      height: 100%;
      &:before {
        opacity: 1;
      }

      .logo {
        visibility: visible;
        opacity: 1;
      }

      .content {
      }
      .nav-toggle {
        .menu {
          .line01 {
            opacity: 0;
            transform: translate(20%);
            transition: opacity .4s .0s $ease-out01, transform .4s .0s $ease-out01, background .2s $ease-out01;
          }
          .line02 {
            opacity: 0;
            transform: translate(20%);
            transition: opacity .4s .05s $ease-out01, transform .4s .05s $ease-out01, background .2s $ease-out01;
          }
          .line03 {
            opacity: 0;
            transform: translate(20%);
            transition: opacity .4s .1s $ease-out01, transform .4s .1s $ease-out01, background .2s $ease-out01;
          }
        }

        .close {
          [class^="line"] {
            transition: all .2s .3s $ease-out01;
            opacity: 1;
            width: 60px;
          }
        }
      }
    }

    // ヘッダーの開閉のトランジションをさせない
    &.is-notTransition {
      transition: 0s;
    }
  }



  body.is-setup-comp {
    .l-header {
      .nav-toggle {
        visibility: visible;
      }
    }
  }
}

