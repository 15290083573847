@import "../import";
@charset "utf-8";

/* ============================================================
   index
============================================================ */

.page-home {
  // fv
  .fv {
    position: relative;
    width:100%;
    height: 800px;
    margin-bottom: 129px;
    overflow: hidden;

    //.slick-list {
    //  height: 100%;
    //}
    //.slick-track {
    //  height: 100%;
    //}

    [class^="img"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      background: #000;

      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .img01 div {
      background: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_pc01.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    .img02 div {
      background: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_pc02.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    .img03 div {
      background: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_pc03.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    .img04 div {
      background: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_pc04.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    .img05 div {
      background: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_pc05.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
  }

  // sec-brands
  .sec-brands {
    .c-hdg-style01 {
      margin-bottom: 125px;
    }
    .brands-list {
      &__item {
        + .brands-list__item {
          margin-top: 150px;
        }
        .content {
          box-sizing: border-box;
          position: relative;
          width: 100%;
          height: 500px;
          display: flex;
          align-items: center;
          background-size: cover;
          justify-content: space-between;
          background-position: 50% 50%;
          font-family: $mincho-font;
          color: #fff;
          padding: 0 118px 0 137px;
          z-index: 5;

          &:before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            display: block;
            width: 100%;
            height: 100%;
            background: #000;
          }
        }

        .logo {
          position: relative;
          background: #fff;
          width: 285px;
          height: 285px;
          img  {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: auto;
          }
        }
        .txt-box {
          position: relative;
          width: 635px;
          text-align: center;

          .txt {
            font-size: 2.0rem;
            letter-spacing: 0.3em;
          }
          .btn {
            margin-top:45px;
            line-height: 1;
            a {
              text-decoration: none;
              color: #fff;
              border-radius: 40px;
              border: 1px solid #fff;
              display: inline-block;
              padding: 10px 30px;
              transition: all .2s $ease-out03;

              &:hover {
                background: rgba(255, 255, 255, 0.9);
                color: $txt-color-main;
              }
            }
          }
        }

        // ブランドホバー
        .content:before,
        .logo,
        .txt-box {
          opacity: 0;
          transition: opacity .4s $ease-out03;
        }
        .content:hover {
          &:before {
            opacity: 0.6;
          }
          .logo,
          .txt-box {
            opacity: 1;
          }
        }
      }
    }
  }

  // sec-products
  .sec-products {
    margin-top: 168px;
    .c-hdg-style01 {
      margin-bottom: 70px;
    }
    .products-list {
      display: flex;
      li {
        width: 16.6%;
        text-align: center;
        a {
           display: block;
           padding: 0 64 / 214 * 100 / 2 + %;
           text-decoration: none;

           &:hover {
             .img {
               opacity: 0.7;
             }
           }
         }
      }
      .img {
        margin-bottom: 17px;
        transition: opacity .3s $ease-out03;
        img {
          width: 100%;
          height: auto;
        }
      }
      .txt {
        line-height: 1.0;
        span {
          display: block;
        }
        .em {
          font-family: $mincho-font;
        }
        .sub {
          margin-top: 6px;
          font-size: 1.0rem;
        }
      }
    }
  }

  // sec-gallery
  .sec-gallery {
    margin-top: 204px;

    .error {
      text-align: center;
    }

    .c-hdg-style01 {
      margin-bottom: 80px;
    }
    .c-sec__contents {
      overflow: hidden;
    }
    .gallery-list {
      width: 100000000px;
      li {
        width: 300px;
        float: left;
        margin: 5px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  // sec-news
  .sec-news {
    margin-top: 135px;

    .c-hdg-style01 {
      margin-bottom: 74px;
    }
    .news-list {
      width: 434px;
      margin: 0 auto;

      li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          display: block;
          padding: 18px 0;
          border-top: 1px solid #EBEBEB;
          display: flex;
          align-items: center;
        }
      }
      li:last-child {
        span {
          border-bottom: 1px solid #EBEBEB;
        }
      }
      .date {
        width: 7em;
        font-size: 1.2rem;
        color: #888888;
      }
      .txt {
        width: 340px;

        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}






/* --------------------------------------------------
   mediaquery -> for-desktop-under
-------------------------------------------------- */

@include for-desktop-under {
  .page-home {
    // sec-brands
    .sec-brands {
      .brands-list {
        &__item {
          + .brands-list__item {
            margin-top: pxToVw(150, $bp-desktop-up);
          }
          .content {
            padding: 0 pxToVw(118, $bp-desktop-up) 0 pxToVw(137, $bp-desktop-up);
            height: pxToVw(500, $bp-desktop-up);
          }
          .logo {
            width: pxToVw(285, $bp-desktop-up);
            height: pxToVw(285, $bp-desktop-up);
          }
          .txt-box {
            width: pxToVw(635, $bp-desktop-up);
            //.txt {
            //  font-size: pxToVw(20, $bp-desktop-up);
            //}
            //.btn {
              margin-top: pxToVw(45, $bp-desktop-up);
            //}
          }
        }
      }
    }

    // sec-gallery
    .sec-gallery {
      .gallery-list {
        li {
          width: pxToVw(300, $bp-desktop-up);
        }
      }
    }
  }
}



/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-home {
    // fv
    .fv {
      height: pxToVw(970, $sp-width);
      margin-bottom: pxToVw(154, $sp-width);
      .img01 div {
        background-image: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_sp01.jpg');
      }
      .img02 div {
        background-image: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_sp02.jpg');
      }
      .img03 div {
        background-image: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_sp03.jpg');
      }
      .img04 div {
        background-image: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_sp04.jpg');
      }
      .img05 div {
        background-image: url('/wp/wp-content/themes/wildwood/assets/images/top/img_mv_sp05.jpg');
      }
    }

    // sec-brands
    // sec-brands
    .sec-brands {
      .c-hdg-style01 {
        margin-bottom: pxToVw(150, $sp-width);
      }
      .brands-list {
        &__item {
          + .brands-list__item {
            margin-top: pxToVw(95, $sp-width);
          }
          .content {
            height: pxToVw(285, $sp-width);
            padding: 0;

            &:before {
              display: none;
            }
            a {
              position: relative;
              display: block;
              width: 100%;
              height:100%;
              padding: pxToVw(28, $sp-width) pxToVw(40, $sp-width);
              box-sizing: border-box;
            }
          }
          .logo {
            width: pxToVw(230, $sp-width);
            height: pxToVw(230, $sp-width);
          }
          .txt-box {
            width: auto;
            margin-top:pxToVw(16, $sp-width);
            text-align: right;
            font-family: $mincho-font;
            .txt {
              font-size:pxToVw(24, $sp-width);
              line-height: 1.4;
              letter-spacing: 0;
            }
          }

          // ブランドホバー
          .logo,
          .txt-box {
            opacity: 1;
          }
        }
      }
    }

    // sec-products
    .sec-products {
      margin-top: pxToVw(156, $sp-width);
      .c-hdg-style01 {
        margin-bottom: pxToVw(100, $sp-width);
      }
      .products-list {
        flex-wrap: wrap;
        margin: pxToVw(-56/2, $sp-width);
        li {
          width: 33.33%;
          a {
            padding: pxToVw(56/2, $sp-width);
          }
        }
        .img {
          margin-bottom: pxToVw(10, $sp-width);
        }
        .txt {
          .em {
            font-size: pxToVw(24, $sp-width);
          }
          .sub {
            font-size: pxToVw(16, $sp-width);
            margin-top: pxToVw(2, $sp-width);
          }
        }
      }
    }

    // sec-gallery
    .sec-gallery {
      margin-top: pxToVw(210, $sp-width);

      .c-hdg-style01 {
        margin-bottom: pxToVw(89, $sp-width);
      }
      .gallery-list {
        li {
          width: pxToVw(300, $sp-width);
          margin: pxToVw(5, $sp-width);
        }
      }
    }

    // sec-news
    .sec-news {
      margin-top: pxToVw(186, $sp-width);

      .c-hdg-style01 {
        margin-bottom: pxToVw(84, $sp-width);
      }
      .c-l-base {
        padding-right:pxToVw(68, $sp-width);
        padding-left:pxToVw(68, $sp-width);
      }

      .news-list {
        width: 100%;
        li {
          span {
            padding: pxToVw(36, $sp-width) 0;
          }
          line-height: 1.6;
        }
        .date {
          font-size: pxToVw(16, $sp-width);
          margin-right: pxToVw(45, $sp-width);
        }
        .txt {
          font-size: pxToVw(24, $sp-width);
          flex-grow: 1;
        }
      }
    }
  }
}


