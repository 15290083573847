@import "../import";
@charset "utf-8";

/* ============================================================
   Base
============================================================ */

head {
	font-family: pc; // jsからmediaquery切り替わりを判定するために使用
}
img {
	width: 100%;
	height: auto;
}
html{
	overflow-y: scroll;
	font-size: 62.5%;
}
body{
	overflow: hidden;
	//min-width: $min-width-pc;
	color: $txt-color-main;
	font-family: $gothic-font;
	line-height: 1.8;
	font-size: 1.4rem;
	letter-spacing: .05em;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%;
	word-wrap: break-word;
}

button {
	font-family: 'NotoSerifJP-local', 'Noto Serif JP', serif;
}
[v-cloak] {
	visibility: hidden;
}

/* selection
------------------------------ */
::-moz-selection {
	background: rgba(60, 60, 60, 0.1);
}
::selection {
	background: rgba(60, 60, 60, 0.1);
}

/* base link
------------------------------ */
a {
	color: $txt-color-main;
	outline: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
a:link,
a:visited {
	text-decoration: underline;
}
a:hover {
	text-decoration: none;
	outline: none;
}
a:active {
	outline: none;
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */
@include for-tablet-portrait-under {
	head {
		font-family: sp; // jsからmediaquery切り替わりを判定するために使用
	}

	body{
		font-size: pxToVw(22, $sp-width);
		line-height: 1.8;
	}
}
