@import "../../import";
@charset "utf-8";

/**
 * margin
 */
.u-mg-gap {
	margin-top: 1em;
}

