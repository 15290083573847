@import "../../import";
@charset "utf-8";

/**
 * ボタンスタイル
 */
p.c-btn-style01,
a.c-btn-style01,
input[type="submit"].c-btn-style01,
button.c-btn-style01 {
  box-sizing: border-box;
  display: inline-block;
  padding: 8px 28px;
  text-decoration: none;
  border: 1px solid $color-gray-dark;
  letter-spacing: .1em;
  transition: all .4s $ease-out04;
  border-radius: 40px;
  font-family:$mincho-font;
  line-height: 1.2;
  outline: 0;
  background: #fff;
  span {
    position: relative;
    padding-left: 1em;

    &:before {
      content: '>';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &:hover {
    color: #fff;
    background: $txt-color-main;
    border-color: $txt-color-main;
  }

  &._size01 {
    font-size: 2.0rem;
    padding: 24px 140px;
  }
  &.is-disabled {
    background: $color-gray-dark;
    color: #fff;
  }
}

input[type="submit"].c-btn-style01,
button.c-btn-style01 {
  cursor: pointer;
}

p.c-btn-style02,
a.c-btn-style02,
input[type="submit"].c-btn-style01,
button.c-btn-style02 {
  box-sizing: border-box;
  display: inline-block;
  padding: 8px 32px;
  text-decoration: none;
  border: 1px solid $color-gray-dark;
  letter-spacing: .1em;
  transition: all .4s $ease-out04;
  line-height: 1.2;
  font-size: 1.0rem;
  outline: 0;

  span {
    position: relative;
    padding-left: 1em;
    &:before {
      content: '>';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &:hover {
    color: #fff;
    background: $txt-color-main;
    border-color: $txt-color-main;
  }
}

input[type="submit"].c-btn-style01,
button.c-btn-style02 {
  cursor: pointer;
}



@keyframes spinnerAnime {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.c-btn-spinner {
  position: relative;
  display: inline-block;

  .spinner {
    visibility: hidden;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 26px;
    height: 26px;
    transform: translate(180%, -50%);
    opacity: 0;
    transition: all .8s $ease-out06;
    pointer-events: none;

    &:after, &:before {
      box-sizing: border-box;
      display: inline-block;
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
    &:after {
      border: solid 4px $color-gray;
    }
    &:before {
      z-index: 5;
      border-top: solid 4px $color-gray-dark;
      border-right: solid 4px transparent;
      border-bottom: solid 4px transparent;
      border-left: solid 4px transparent;
      animation: spinnerAnime .6s linear infinite;
    }
  }

  // sending animation
  &.is-sending {
    .spinner {
      visibility: visible;
      transform: translate(180%, -50%);
      opacity: 1;
    }
  }
}



/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  a.c-btn-style01,
  input[type="submit"].c-btn-style01,
  button.c-btn-style01 {
    padding: pxToVw(20, $sp-width) pxToVw(65, $sp-width);
    border-radius: pxToVw(40, $sp-width);

    &._size01 {
      font-size: pxToVw(24, $sp-width);
      padding: pxToVw(20, $sp-width) pxToVw(110, $sp-width);
    }
  }

  a.c-btn-style02,
  input[type="submit"].c-btn-style01,
  button.c-btn-style02 {
    padding: pxToVw(16, $sp-width) pxToVw(53, $sp-width);
    font-size: pxToVw(20, $sp-width);
  }



  .c-btn-spinner {
    .spinner {
      width: pxToVw(40, $sp-width);
      height: pxToVw(40, $sp-width);
      &:after, &:before {
        border-width: pxToVw(6, $sp-width);
      }
    }

    // sending animation
    &.is-sending {
      .spinner {
        transform: translate(160%, -50%);
        opacity: 1;
      }
    }
  }
}
