@charset "UTF-8";

/// ============================================================
/// MIXIN
/// プロジェクト用
/// ============================================================

//@mixin for-phone-only {
//  @media (max-width: 599px) { @content; }
//}
@mixin for-tablet-portrait-under {
  @media (max-width: $bp-tb-port-up + px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $bp-tb-port-up + 1 + px) { @content; }
}
//@mixin for-tablet-landscape-up {
//  @media (min-width: 900px) { @content; }
//}
@mixin for-desktop-under {
  @media (max-width: $bp-desktop-up + px) { @content; }
}

//@mixin for-big-desktop-up {
//  @media (min-width: 1800px) { @content; }
//}
//
//// usage
//.my-box {
//  padding: 10px;
//
//  @include for-desktop-up {
//    padding: 20px;
//  }
//}
