@import "../../import";
@charset "utf-8";

/**
 * ブロックスタイル
 */

/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-up
-------------------------------------------------- */

@include for-tablet-portrait-up {
	.u-sp {
		display: none !important;
	}
}

/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
	.u-pc {
		display: none !important;
	}
}