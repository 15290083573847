@import "../../import";
@charset "utf-8";

 /**
  * contact動線
  */
.p-contact {
  a {
    position: relative;
    display: block;
    text-decoration: none;
    text-align: center;
    padding: 97px 0;
    background: url("/wp/wp-content/themes/wildwood/assets/images/common/img_tex01.png");
    color: $txt-color-main;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background: rgba(60, 60, 60, 0.05);
      opacity: 0;
      transition: all .4s $ease-out04;
    }

    .em {
      font-size: 36px;
      letter-spacing: .2em;
    }
    .sub {
      margin-top: 10px;
      font-size: 1.0rem;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
}



/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .p-contact {
    a {
      padding: pxToVw(109, $sp-width) 0;
      background-size: 53px 39px;

      .em {
        font-size: pxToVw(43, $sp-width);
      }
      .sub {
        margin-top: pxToVw(9, $sp-width);
        font-size: pxToVw(16, $sp-width);
      }
    }
  }
}