@import "../../import";
@charset "utf-8";

/**
 * ブロックスタイル
 */
.c-block-style01 {
  display: flex;
  flex-direction: row-reverse;

  + .c-block-style01 {
    margin-top: 120px;
  }
  &__cont {
    box-sizing: border-box;
    width: 50%;
    padding-right: 40px;
    padding-left: 50px;;
  }
  &__img {
    width: 50%;
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .c-block-style01 {
    display: block;
    //flex-direction: column-reverse;

    + .c-block-style01 {
      margin-top: pxToVw(120, $sp-width);
    }
    &__cont {
      width: 100%;
      margin-top:pxToVw(50, $sp-width);
      padding-right:pxToVw(24, $sp-width);
      padding-left:pxToVw(24, $sp-width);
      font-size: pxToVw(22, $sp-width);
      line-height: 2;
    }
    &__img {
      width: 100%;
    }
  }
}