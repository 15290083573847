@import "../../import";
@charset "utf-8";

/* ============================================================
   news
============================================================ */
.page-news-index {
  .news-list {
    &__item {
      + .news-list__item {
        padding-top: 37px;
      }
    }
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-news-index {
    .news-list {
      &__item {
        + .news-list__item {
          padding-top: pxToVw(108, $sp-width);
        }
      }
    }
  }
}
