@import "../import";
@charset "utf-8";


/* ============================================================
   Page
============================================================ */
.l-page {
  position: relative;

}
.l-wrapper {
  padding-top: 102px;
  padding-bottom: 158px;
}



/* --------------------------------------------------
   mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .l-page {
  }
  .l-wrapper {
    padding-top: pxToVw(116, $sp-width);
    padding-bottom: pxToVw(142, $sp-width);
  }
}


/* ============================================================
   Content
============================================================ */
.l-content {
  
  &.is-float {
    position: relative;
    z-index: 13000;
  }
}
