@import "../../import";
@charset "utf-8";

/* ============================================================
   contact 送信完了
============================================================ */


.page-contact-complete {
  .c-sec__contents {
    text-align: center;
    .hdg {
      font-size: 2.0rem;
      font-family: $mincho-font;
    }
    .txt {
      margin-top: 105px;
      font-size: 1.4rem;
    }
    .btn {
      margin-top: 137px;
    }
  }
}



/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-contact-complete {
    // 完了画面
    .c-sec__contents {
      .hdg {
        font-size: pxToVw(30, $sp-width);
      }
      .txt {
        font-size: pxToVw(24, $sp-width);
        text-align: left;
        margin-top: pxToVw(110, $sp-width);
      }
      .btn {
        margin-top: pxToVw(136, $sp-width);
      }
    }
  }
}
