@import "../../import";
@charset "utf-8";


/**
 * タイトル
 */
.c-hdg-style01 {
  text-align: center;
  line-height: 1;
  .em {
    font-family: $mincho-font;
    font-size: 3.6rem;
    letter-spacing: .25em;
    span {
      position: relative;
      display: inline-block;
      padding: 0 54px;
      &:before , &:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 21px;
        height: 7px;
        background: url('/wp/wp-content/themes/wildwood/assets/images/common/img_hdg_line01.png') no-repeat;
        background-size: 21px 7px;
      }
      &:before {
        left:0;
      }
      &:after {
        right: 0;
      }
    }
  }
  .sub {
    margin-top: 10px;
    font-size: 1.0rem;
  }
}

.c-hdg-style02 {
  text-align: center;
  line-height: 1;
  background: url("/wp/wp-content/themes/wildwood/assets/images/common/img_tex01.png");
  padding: 100px 88px;
  margin-bottom: 108px;

  .em {
    font-family: $mincho-font;
    font-size: 3.6rem;
    letter-spacing: .25em;
    span {
      position: relative;
      display: inline-block;
      padding: 0 54px;
      &:before , &:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 21px;
        height: 7px;
        background: url('/wp/wp-content/themes/wildwood/assets/images/common/img_hdg_line01.png') no-repeat;
        background-size: 21px 7px;
      }
      &:before {
        left:0;
      }
      &:after {
        right: 0;
      }
    }
  }
  .sub {
    margin-top: 10px;
    font-size: 1.0rem;
  }
}

.c-hdg-style03 {
  text-align: center;
  line-height: 1;
  margin-bottom: 65px;
  &__inner {
    position: relative;
    display: inline-block;
    padding: 0 74px;
    &:before , &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      display: block;
      width: 43px;
      height: 4px;
      background: url('/wp/wp-content/themes/wildwood/assets/images/common/img_hdg_line02.png') no-repeat;
      background-size: 43px 4px;
    }
    &:before {
      left:0;
    }
    &:after {
      right: 0;
    }
  }
  .em {
    font-family: $mincho-font;
    font-size: 2.2rem;
    letter-spacing: .1em;
  }
  .sub {
    margin-top: 4px;
    font-size: 1.0rem;
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .c-hdg-style01 {
    .em {
      font-size: pxToVw(43, $sp-width);
      span {
        padding: 0 pxToVw(56, $sp-width);
        &:before , &:after {
          width: pxToVw(25, $sp-width);
          height: pxToVw(9, $sp-width);
          background-size: pxToVw(25, $sp-width) pxToVw(9, $sp-width);
        }
        &:before {
          left:0;
        }
        &:after {
          right: 0;
        }
      }
    }
    .sub {
      margin-top: pxToVw(9, $sp-width);
      font-size: pxToVw(16, $sp-width);
    }
  }


  .c-hdg-style02 {
    padding: pxToVw(166, $sp-width) 0;
    margin-bottom: pxToVw(100, $sp-width);
    .em {
      font-size: pxToVw(43, $sp-width);
      span {
        padding: 0 pxToVw(56, $sp-width);
        &:before , &:after {
          width: pxToVw(25, $sp-width);
          height: pxToVw(9, $sp-width);
          background-size: pxToVw(25, $sp-width) pxToVw(9, $sp-width);
        }
        &:before {
          left:0;
        }
        &:after {
          right: 0;
        }
      }
    }
    .sub {
      margin-top: pxToVw(9, $sp-width);
      font-size: pxToVw(16, $sp-width);
    }
  }


  .c-hdg-style03 {
    margin-bottom: pxToVw(95, $sp-width);
    &__inner {
      padding: 0 pxToVw(90, $sp-width);
      &:before , &:after {
        width: pxToVw(52, $sp-width);
        height: pxToVw(9, $sp-width);
        background-size: pxToVw(52, $sp-width) pxToVw(9, $sp-width);
      }
    }
    .em {
      font-size: pxToVw(30, $sp-width);
    }
    .sub {
      margin-top: pxToVw(7, $sp-width);
      font-size: pxToVw(16, $sp-width);
    }
  }
}
