@import "../../import";
@charset "utf-8";

/* ============================================================
   brands
============================================================ */

.page-brands {
  .c-hdg-style02 {
    margin-bottom: 0;
  }
  .brand-anchor {
    margin-bottom: 128px;
    overflow: hidden;
    padding: 26px 0;
    border-bottom: 1px solid #E5E5E5;
    ul {
      width: 100000000px;
    }
    li {
      cursor: pointer;
      float: left;
      width: 179px;
      padding: 0 10px;
      img {
        float: left;
        width: 100%;
        height: auto;
      }
    }
  }
  .slider {
    opacity: 0;
    transition: opacity .3s linear;
    min-height: 400px;
    line-height: 1;
    &.slick-initialized{
      opacity: 1;
    }
    .slick-arrow{
      &:before{
        opacity: 0;
      }
    }
    .slick-next{
      right: 22px;
      width: 19px;
      height: 37px;
      background-image: url('/wp/wp-content/themes/wildwood/assets/images/common/img_arw_r.png');
      z-index: 5;
      transition: all .4s $ease-out04;
      &:hover {
        opacity: 0.7;
      }
    }
    .slick-prev{
      left: 22px;
      width: 19px;
      height: 37px;
      background-image: url('/wp/wp-content/themes/wildwood/assets/images/common/img_arw_l.png');
      z-index: 5;
      opacity: 0.7;
      transition: all .4s $ease-out04;
      &:hover {
        opacity: 0.7;
      }
    }
    .slick-dots {
      bottom: 20px;
      line-height: 1;
      li {
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        margin: 0 8px;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 50%;
        button {
          width: 100%;
          height: 100%;
          &:before {
            content: '';
            background: #000;
            width: 7px;
            height: 7px;
            background: #707070;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .4s $ease-out03;
          }
        }
        &.slick-active {
          button {
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .brand-list {
    margin-top: -126px;
  }

  [class^="brand-list__item"] {
    margin-top: 125px;

    .info {
      display: flex;
      &__img {
        width: pxToVw(390, $bp-desktop-up);
        img {
          width:  100%;
        }
        .movie {
          position: relative;
          padding-bottom: 56.25%; /*アスペクト比 16:9の場合の縦幅*/
          height: 0;
          overflow: hidden;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      &__cont {
        box-sizing: border-box;
        width: pxToVw(830, $bp-desktop-up);
        padding-left: 40px;
        dt {
          line-height: 1.2;
          span {
            display: block;
          }
        }
        dd {
          line-height: 2;
        }
        .sub {
          font-size: 1.2rem;
          margin-bottom: 3px;
        }
        .em {
          font-family: $mincho-font;
          font-size: 1.8rem;
          font-weight: bold;
        }
        .lead {
        }
        .txt {
        }
        .site {
          margin-top: 2.5em;
          font-size: 1.2rem;
          line-height: 1.6;
          a {
          }
        }
      }
      &__link {
        margin-top: 26px;
        text-align: right;
      }
    }
  }
  .brand-list__item {
    &--type01 {
      .lead {
        margin-top: 60px;
        text-align: center;
        &__logo {
          img {
            width:390px;
            height:auto;
          }
        }
        &__txt {
          font-family: $mincho-font;
          font-size: 2.2rem;
          letter-spacing: 0.23em;
          margin-top: 25px;
        }
      }
      .info {
        margin-top: 136px;
        &__img {
        }
        &__cont {
          dt {
            margin-bottom: 40px;
          }
          dd {
          }
        }
        &__link {
        }
      }
    }

    &--type02 {
      .info {
        margin-top: 136px;
        &__cont {
          dt {
            margin-bottom: 10px;
          }
          .lead {
            font-weight: bold;
            font-family: $mincho-font;
            margin-bottom:30px;
          }
        }
      }
    }
  }
}




/* --------------------------------------------------
   mediaquery -> for-desktop-under
-------------------------------------------------- */

@include for-desktop-under {
  .page-brands {
    .slider {
      min-height: pxToVw(400, $bp-desktop-up);
    }
  }
}




/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-brands {
    .brand-anchor {
      margin-bottom: pxToVw(133, $sp-width);
      padding: pxToVw(25, $sp-width) 0;
      li {
        padding: 0 pxToVw(10, $sp-width);
        width: pxToVw(157, $sp-width);
      }
    }
    .slider {
      min-height: pxToVw(240, $sp-width);

      .slick-next{
        right: pxToVw(16, $sp-width);
        width: 15px;
        height: 30px;
        background-size: 15px 30px;
      }
      .slick-prev{
        left: pxToVw(15, $sp-width);
        width: 15px;
        height: 30px;
        background-size: 15px 30px;
      }
      .slick-dots {
        bottom: pxToVw(16, $sp-width);
        li {
          width: 8px;
          height: 8px;
          margin: 0 4px;
          button {
            &:before {
              width: 4px;
              height: 4px;
            }
          }
        }
      }
    }
    .brand-list {
      margin-top: pxToVw(-167, $sp-width);
    }

    [class^="brand-list__item"] {
      margin-top: pxToVw(167, $sp-width);

      .info {
        display: block;
        &__img {
          text-align: center;
          width: pxToVw(430, $sp-width);
          margin:  0 auto;
        }
        &__cont {
          width: 100%;
          margin:  pxToVw(66, $sp-width) auto 0;
          padding-left: 0;
          dt {
          }
          dd {
          }
          .sub {
            font-size: 1.2rem;
            font-size: pxToVw(16, $sp-width);
          }
          .em {
            font-size: pxToVw(30, $sp-width);
          }
          .lead {
          }
          .txt {
          }
          .site {
            font-size: pxToVw(24, $sp-width);
            a {
            }
          }
        }
        &__link {
          margin-top: pxToVw(90, $sp-width);
          text-align: center;
        }
      }
    }
    .brand-list__item {
      &--type01 {
        .lead {
          margin-top: pxToVw(93, $sp-width);
          &__logo {
            img {
              width: pxToVw(430, $sp-width);
            }
          }
          &__txt {
            letter-spacing: 0.1;
            font-size: pxToVw(30, $sp-width);
            margin-top: pxToVw(53, $sp-width);
          }
        }
        .info {
          margin-top: pxToVw(101, $sp-width);
          &__img {
          }
          &__cont {
            dt {
              margin-bottom: pxToVw(66, $sp-width);
            }
          }
          &__link {
          }
        }
      }

      &--type02 {
        .info {
          margin-top: pxToVw(167, $sp-width);
          &__cont {
            dt {
              margin-bottom: pxToVw(48, $sp-width);
            }
            .lead {
              margin-bottom: pxToVw(70, $sp-width);
              font-size: pxToVw(30, $sp-width);
            }
          }
        }
      }
    }
  }
}
