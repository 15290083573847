@import "../../import";
@charset "utf-8";

/* ============================================================
   news 共通
============================================================ */

.page-news {
  .cmn-content {
    display: flex;
    &__l {
      width: pxToVw(778, $bp-desktop-up);
    }
    &__r {
      box-sizing: border-box;
      width: pxToVw(370, $bp-desktop-up);
      padding-left: 65px;
    }
  }
  .cmn-article {
    padding-bottom: 74px;
    border-bottom: 1px solid $color-gray;

    &__img {
      img {
        width: 100%;
        height:auto;
      }
    }
    &__head {
      margin-top: 42px;
      .date {
        margin-bottom: 12px;
        font-size:1.0rem;
        line-height: 1;
      }
      .hdg {
        font-size: 2.0rem;
        font-family: $mincho-font;
        line-height: 1.2;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &__body {
      margin-top: 55px;
    }
  }
  .cmn-bottom {
    margin-top: 40px;
  }

  .cmn-nav {
    &__block {
      dt {
        font-size: 1.4rem;
        font-family: $mincho-font;
        text-align: right;
        padding-bottom:32px;
        border-bottom: 1px solid $color-gray;
        line-height: 1.2;
      }
      dd {
        ul {
        }
        li {
          border-bottom: 1px solid $color-gray;
          line-height: 1.4;
        }
        a {
          display: block;
          padding: 17px 0;
          text-decoration: none;
          transition: all .4s $ease-out04;
          &:hover {
            background: #f4f4f4;
          }
        }
      }
      &--recent {
        span {
          display: block;
        }
        .date {
          font-size: 1.0rem;
          margin-bottom: 4px;
          line-height: 1;
        }
        .txt {
          font-family: $mincho-font;
          font-size: 1.2rem;
        }
      }
      &--year {
        margin-top: 77px;
        li {
          font-size: 1.2rem;
          font-family: $mincho-font;
        }
      }
    }
  }
}


/* --------------------------------------------------
 mediaquery -> for-tablet-portrait-under
-------------------------------------------------- */

@include for-tablet-portrait-under {
  .page-news {
    .cmn-content {
      display: block;
      &__l {
        width: 100%;
      }
      &__r {
        width: 100%;
        padding-left: 0;
      }
    }
    .cmn-article {
      padding-bottom: pxToVw(130, $sp-width);
      &__img {
      }
      &__head {
        margin-top: pxToVw(85, $sp-width);
        .date {
          margin-bottom: pxToVw(20, $sp-width);
          font-size:pxToVw(16, $sp-width);
        }
        .hdg {
          font-size: pxToVw(30, $sp-width);
        }
      }
      &__body {
        margin-top: pxToVw(110, $sp-width);
      }
    }
    .cmn-bottom {
      margin-top: pxToVw(60, $sp-width);
    }

    .cmn-nav {
      margin-top: pxToVw(194, $sp-width);
      &__block {
        dt {
          font-size: pxToVw(30, $sp-width);
          padding-bottom:pxToVw(50, $sp-width);
        }
        dd {
          a {
            padding: pxToVw(35, $sp-width) 0;
          }
        }
        &--recent {
          .date {
            font-size: pxToVw(16, $sp-width);
            margin-bottom: pxToVw(20, $sp-width);
          }
          .txt {
            font-size: pxToVw(30, $sp-width);
          }
        }
        &--year {
          margin-top: pxToVw(125, $sp-width);
          li {
            font-size: pxToVw(30, $sp-width);
          }
        }
      }
    }
  }
}
